import React, { Component } from "react"
import Select from 'react-select'
import axios from 'axios';
import { Container, Row, Col, Button} from "react-bootstrap"
import { ApiContext } from '../../contexts/apicontext';

export default class PlanSelect extends Component {
    constructor(props) {
        super(props);
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.mppBaseUrl = process.env.REACT_APP_MPP_BASE_URL;
        if(this.props.goodsam) {
            if(this.props.upgrade) {
                this.allowed = ['gsu'];
            } else {
                this.allowed = ['gsd', 'gsm', 'gsmd'];
            }
        } else {
            if(this.props.upgrade) {
                this.allowed = ['rvu'];
            } else {
                this.allowed = ['rvd', 'rvm', 'rvmd'];
            }
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static contextType = ApiContext

    handlePlanChange = (object) => {
        const options = Object.keys(this.state.products[object.value]['contracts']).map((obj, index) =>  {
            const data = {value: obj, label: this.state.products[object.value]['contracts'][obj].length + " " + this.state.products[object.value]['contracts'][obj].period + " for " +this.state.products[object.value]['contracts'][obj].price};
            return data;
        });
        this.setState({selectedPlan:object.value, contracts:options});
    }

    handleContractChange = (object) => {
        const cartReference = this.state.products[this.state.selectedPlan]['contracts'][object.value].cartReference;
        this.setState({selectedContract:object.value,selectedCartReference:cartReference});
    }

    handleSubmit() {
        console.log("Next Step!");
        this.props.handleSubmit(this.state.selectedPlan, this.state.selectedContract, this.state.selectedCartReference);
    }

    state = {
        productOptions: [],
        products: [],
        contracts: [],
        selectedPlan: null,
        selectedContract: null,
        selectedCartReference: null
    }

  componentDidMount() {
    const bodyParameters = {
        "upgrade": this.props.upgrade
    };
    const config = {
        headers: { Authorization: "Bearer " + this.context.mpptoken}
    };
    axios.post(this.mppBaseUrl + `/mpp/products`,
        bodyParameters,
        config
    )
    .then(res => {
        const response = res.data;
        const filtered = Object.keys(response.data.products)
        .filter(key => this.allowed.includes(key))
        .reduce((obj, key) => {
            obj[key] = response.data.products[key]['name'] + " starting at $" + response.data.products[key]['starting'];
            return obj;
        }, {});
        const options = Object.keys(filtered).map((obj, index) =>  {
            const object = {value: obj, label: filtered[obj]};
            return object;
        });
        console.log(options);
        this.setState({productOptions: options, products: response.data.products});
    })
    .catch((error) => { console.log(error) } );
  }



  render() {
    return (
    <Container>
        <Row>
            <Col><h3>Subscription Type</h3></Col>
            {this.state.selectedPlan && <Col><h3>Contract Length</h3></Col>}
        </Row>
        <Row>
            <Col><Select options={this.state.productOptions} onChange={(object) => this.handlePlanChange(object)}></Select></Col>
            {this.state.selectedPlan && <Col><Select options={this.state.contracts} onChange={(object) => this.handleContractChange(object)}></Select></Col>}
        </Row>
        {this.state.selectedPlan && this.state.selectedContract && <Button className="btn btn-secondary w-100 mt-3" onClick={this.handleSubmit}>
                    Continue to Address
        </Button>}
    </Container>
    );
  }

}