import React from "react"
import Modal from 'react-bootstrap/Modal'
import { Container, Row, Col, Button } from "react-bootstrap"

export default function CreateAccountModal(props) {
    let firstName = React.createRef();
    let lastName = React.createRef();
    let email = React.createRef();
    let zipcode = React.createRef();
    let subscribe = React.createRef();

    var userObject = {
        firstName: null,
        lastName: null,
        email: null,
        zipCode: null,
        subscribe: false
    }

    function submit(e) {
        userObject.firstName = firstName.current.value
        userObject.lastName = lastName.current.value
        userObject.email = email.current.value
        userObject.zipCode = zipcode.current.value
        userObject.subscribe = subscribe.current.value
        props.submit(userObject)
    }
    
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create New Account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
        <Container>
          <Row>
            <Col xs={6}>
                <label htmlFor="firstName">First Name: </label> <input type="text" ref={firstName} name="firstName" />
            </Col>
            <Col xs={6}>
                <label htmlFor="lastName">Last Name: </label> <input type="text" ref={lastName} name="lastName" />
            </Col>
            <Col xs={6}>
                <label htmlFor="email">Email: </label> <input type="email" ref={email} name="email" />
            </Col>
            <Col xs={6}>
                <label htmlFor="lastName">Zip Code: </label> <input type="text" ref={zipcode} name="zipcode" />
            </Col>
            <Col xs={12}>
                <input type="checkbox" ref={subscribe} name="subscribe" /> <label htmlFor="subscribe">Yes, I would like to receive email updates from RV.com. I can unsubscribe at anytime. </label> 
            </Col>
          </Row>
        </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={submit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    );
  }