import React, { Component } from "react"
import axios from 'axios';
import { Container, Row, Col, Button} from "react-bootstrap"
import { ApiContext } from '../../contexts/apicontext';

export default class GoodsamValidate extends Component {
  constructor(props) {
    super(props);
    this.baseUrl = process.env.REACT_APP_BASE_URL;
    this.goodsamid = React.createRef();
  }

  state = {
      errorMessage: null
  }

  static contextType = ApiContext

  handleSubmit = (f) => {
    this.setState({errorMessage: null});
    if(f === true) {
      const bodyParameters = {
          "goodsamid": this.goodsamid.current.value
      };
      const config = {
        headers: { Authorization: "Bearer " + this.context.token}
      };
      axios.post(this.baseUrl + `/neuron/goodsam/validate`,
        bodyParameters,
        config
      ).then(res => {
        const response = res.data;
        if(response.isValid) {
            this.props.validate()
        } else {
            this.setState({errorMessage: "Goodsam Account not Active."});
        }
      }).catch((error) => { console.log(error.response) } );
    } else {
        this.props.skip()
    }
  }

  render() {
    return (
      <Container>
          {this.state.errorMessage && <p className="error_message">{this.state.errorMessage}</p>}
          <Row>
            <Col><label htmlFor="GoodsamAccount">Account Number: </label> <input type="text" ref={this.goodsamid} name="GoodsamAccount" /></Col>
          </Row>
          <Row>
            <Col><Button variant="primary" onClick={ () => this.handleSubmit(true) }>Validate Goodsam Account</Button></Col>
            <Col><Button variant="secondary" onClick={ () => this.handleSubmit(false) }>Skip Validation</Button></Col>
          </Row>
      </Container>
    );
  }

}