import React, { Component } from "react"
import axios from 'axios';
import { Container, Row, Col, Button} from "react-bootstrap"
import { authContext } from '../adalConfig';
import { ApiContext } from '../../contexts/apicontext';

export default class ConfirmCheckout extends Component {
  constructor(props) {
    super(props);
    this.baseUrl = process.env.REACT_APP_BASE_URL;
    this.mppBaseUrl = process.env.REACT_APP_MPP_BASE_URL;
  }

  static contextType = ApiContext

  state = {
    cart: null,
    address: null,
    error_message: null
  }

  componentDidMount() {
    const bodyParameters = {
      "tag": this.props.checkoutData.product.replace("gs", "").replace("rv", ""),
      "goodsamValid": this.props.goodsam,
      "contractReference": this.props.checkoutData.contract,
      "accountReference": this.props.customer.accountReference,
      "addressReference": this.props.checkoutData.addressReference,
      "address":true,
      "upgrade": this.props.upgrade
    };        
    const config = {
      headers: { Authorization: "Bearer " + this.context.mpptoken}
    };
    axios.post(this.mppBaseUrl + `/mpp/products`,
      bodyParameters,
      config
    )
    .then(res => {
        const response = res.data;
        this.setState({cart: response.data.product, address: response.data.fullAddress})
    })
    .catch((error) => { console.log(error) } );
  }

  handleSubmit = () => {
    const bodyParameters = {
      "bundleCartReference":this.props.checkoutData.cartReference,
      "deliveryReference":this.props.checkoutData.deliveryReference,
      "addressReference":this.props.checkoutData.addressReference,
      "contractReference":this.props.checkoutData.contract,
      "accountReference":this.props.customer.accountReference,
      "agentEmail": authContext._user.profile.unique_name,
      "email":this.props.customer.email,
      "goodsam":this.props.customer.goodsamValid,
      "promocode":"",
      "cartType":this.props.checkoutData.product.replace("gs", "").replace("rv", "")
    };
    const config = {
      headers: { Authorization: "Bearer " + this.context.mpptoken}
    };
    axios.post(this.mppBaseUrl + `/mpp/cart/purchase`,
      bodyParameters,
      config
    )
    .then(res => {
        const response = res.data;
        if(response.status === "success") {
          this.props.handleSubmit(response)
        } else {
          this.setState({error_message: response.data})
        }
    })
    .catch((error) => { console.log(error) } );
  }

  render() {
    return (
      <Container>
        <Row>
          {this.state.error_message && <p className="error_message">{this.state.error_message}</p>}
        </Row>
        <Row>
          <Col>
            <h3>Address</h3>
            {this.state.address && <p>{this.state.address.street} - {this.state.address.houseNumber}<br></br>
            {this.state.address.townCity}, {this.state.address.state} {this.state.address.postCode}<br></br>
            {this.state.address.country}</p>}
          </Col>
          <Col>
            <h3>Cart</h3>
            {this.state.cart && <p>
            Type: {this.state.cart.name}<br></br>
            Length: {this.state.cart.contract.length} {this.state.cart.contract.period}<br></br>
            Subtotal: {this.state.cart.contract.finalprice - this.state.cart.contract.tax}<br></br>
            Tax: {this.state.cart.contract.tax}<br></br>
            Total: {this.state.cart.contract.finalprice}<br></br>
              
            </p>}
          </Col>
        </Row>
        <Row>
          <Col><Button variant="primary" onClick={ () => this.handleSubmit() }>Complete Checkout</Button></Col>
        </Row>
      </Container>
    );
  }

}