import { AuthenticationContext } from 'react-adal';
// Endpoint URL
export const endpoint = 'https://cwgs.sharepoint.com/';
// App Registration ID
const appId = 'e3bf4f27-6088-4802-b203-43abb8f9b127';
const adalConfig = {
  cacheLocation: 'localStorage',
  clientId: appId,
  endpoints: {
      api:endpoint
  },
  postLogoutRedirectUri: window.location.origin,
  tenant: 'cwgs.onmicrosoft.com'
};
export const authContext = new AuthenticationContext(adalConfig);