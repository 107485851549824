import React from "react"
import ReactDOM from "react-dom"
import App from "./components/App"
import { runWithAdal } from 'react-adal';
import { authContext } from './components/adalConfig';
import { ApiContext } from './contexts/apicontext';
import "bootstrap/dist/css/bootstrap.min.css"

const DO_NOT_LOGIN = false;

runWithAdal(authContext, () => {
ReactDOM.render(
  <React.StrictMode>
    <ApiContext.Provider>
      <App />
    </ApiContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
},DO_NOT_LOGIN);
