import React, { createContext, useState } from "react";

export const ApiContext = createContext();

// This context provider is passed to any component requiring the context
export const ApiProvider = ({ children }) => {
  const [token, setToken, mpptoken, setmppToken] = useState("");
  console.log(token);
  console.log(mpptoken)
  return (
    <ApiContext.Provider
      value={{
        token,
        setToken,
        mpptoken,
        setmppToken
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};