import React from "react"
import { Button } from "react-bootstrap"
import { authContext } from '../components/adalConfig';

export default function Header() {

  return (
    <div className="main-navigation">
        <Button variant="link" onClick={() => authContext.logOut()}>
        Log Out
        </Button>
    </div>
  )
}
