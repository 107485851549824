import React, { Component } from "react"
import { Container, Row, Col, Card, Button} from "react-bootstrap"
import Header from "./header"
import axios from 'axios';
import CreateAccountModal from "./CreateAccountModal";
import GoodsamValidate from "./checkout/GoodsamValidate";
import PlanSelect from "./checkout/PlanSelect";
import AddAddress from "./checkout/AddAddress";
import AddCard from "./checkout/AddCard";
import ConfirmCheckout from "./checkout/ConfirmCheckout";
import CheckoutComplete from "./checkout/CheckoutComplete";
import { ApiContext } from '../contexts/apicontext';


export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
    this.baseUrl = process.env.REACT_APP_BASE_URL;
    this.eSuiteBaseUrl = process.env.REACT_APP_ESUITE_BASE_URL;
    this.mppBaseUrl = process.env.REACT_APP_MPP_BASE_URL;
  }

  static contextType = ApiContext

  state = {
    customer: {},
    checkout: false,
    checkoutStep: 0,
    checkoutData: null,
    goodsam: false,
    error: null,
    modalShow: false,
    cartPurchaseResponse: null,
    showprint: false,
    printData: null,
    mppData: null,
    mppManage: null
  }


  setModalShow = (f) => {
    this.setState({
      modalShow: f,
    })
  }

  convertMPP = (f) => {
    this.setState({ error: "Functionality temporarily disabled" });
  }

  stateChange = (f) => {
    const {name, value} = f.target;
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if(value.length >= 3) {
      const bodyParameters = {
          [name]: value
      };
      const config = {
        headers: { Authorization: "Bearer " + this.context.token}
      };
      axios.post(this.baseUrl + `/call-center/search`,
        bodyParameters,
        config
      )
      .then(res => {
        const customer = res.data;
        console.log(customer);
        if(customer['status'] === "error") {
          this.setState({
            error: customer['error_message'],
            customer: null,
            checkout: false,
            checkoutStep: 0,
            checkoutData: null,
            goodsam: false,
            modalShow: false,
            cartPurchaseResponse: null,
            showprint: false,
            printData: null,
            upgrade: false,
            mppData: null,
            mppManage: null
          });
        } else {
          this.setState({ 
            customer: res.data,
            checkout: false,
            checkoutStep: 0,
            checkoutData: null,
            goodsam: false,
            error: null,
            modalShow: false,
            cartPurchaseResponse: null,
            showprint: false,
            printData: null,
            upgrade: false,
            mppData: null,
            mppManage: null
          });
          if(typeof(customer.printData) !== 'undefined' || customer.owner === "CDS" || customer.owner === "BOTH") {
            const printData = typeof(customer.printData) !== 'undefined' ? customer.printData : customer
            this.setState({printData: printData, upgrade: true});
          }
        }
      })
      .catch((error) => { console.log(error.response) } );
      
      this.setState({
        user: "valid",
      })
    }
    }, 1000);
  }

  checkoutStart = (f) => {
    console.log("Checkout Process");
    this.setState({
      checkout: true,
      showprint: false,
      checkoutStep: 0
    });
  }

  createNewAccount = (f) => {
    const bodyParameters = f;
    const config = {
      headers: { Authorization: "Bearer " + this.context.token}
    };
    axios.post(this.baseUrl + `/call-center/users/create`,
      bodyParameters,
      config
    )
    .then(res => {
      const customer = res.data;
      console.log(customer);
      if(customer['status'] === "success") {
        this.setState({error: "Successfuly Created MPP Account", customer: customer.data, modalShow: false});
      } else {
        this.setState({ error: customer.error_message, modalShow: false });
      }
    })
    .catch(() => { this.setState({ modalShow: false }) } );
  }

  createFromSalesforce = (f) => {
    const bodyParameters = {
      "user_id": this.state.customer.Id
    }
    const config = {
      headers: { Authorization: "Bearer " + this.context.token}
    };
    axios.post(this.baseUrl + `/call-center/mpp/createFromSalesforce`,
      bodyParameters,
      config
    ).then(res => {
      const customer = res.data;
      console.log(customer);
      if(customer['status'] === "success") {
        this.setState({error: "Successfuly Created MPP Account", customer: customer.data});
      } else {
        this.setState({ error: customer.error_message });
      }
    })
    .catch(() => { this.setState({ modalShow: false }) } );
  }

  createFromCDS = (f) => {
    this.setState({ error: "Featured temporarily Disabled" });
  }

  showPrint = (f) => {
    this.setState({showprint: true, checkout: false})
  }

  validateGoodsam = (f) => {
    console.log("validated goodsam");
    this.setState({ goodsam: true, checkoutStep: 1 });
  }

  skipGoodsamValidation = (f) => {
    console.log("skipping goodsam");
    this.setState({ checkoutStep: 1 });
  }

  SelectPlan = (product, contract, cartReference) => {
    let updatedData = this.state.checkoutData
    if(!this.state.checkoutData) {
      updatedData = {}
    }
    updatedData.product = product;
    updatedData.contract = contract;
    updatedData.cartReference = cartReference;
    this.setState({ checkoutStep: 2, checkoutData: updatedData });
    console.log(this.state);
  }

  AddAddress = (addressReference, deliveryReference, address) => {
    console.log(address);
    if(this.state.mppManage === "Shipping") {
      let updateData = this.state.mppData;
      updateData.address.street = address.street
      updateData.address.houseNumber = address.street2
      updateData.address.townCity = address.city
      updateData.address.state = address.state
      updateData.address.country = address.country
      this.setState({
        mppManage: null,
        mppData: updateData
      });
    } else {
      let updatedData = this.state.checkoutData
      updatedData.addressReference = addressReference;
      updatedData.deliveryReference = deliveryReference;
      this.setState({ checkoutStep: 3, checkoutData: updatedData });
    }
  }

  AddCard = (f) => {
    if(this.state.mppManage === "Card") {
      this.setState({mppManage: null});
    } else {
      this.setState({checkoutStep: 4});
    }
    console.log(this.state);
  }

  CompleteCheckout = (status) => {
    this.setState({checkoutStep: 5, cartPurchaseResponse: status.data});
    console.log(this.state);
  }

  mppManage = (f) => {
    console.log("managing MPP");
    const bodyParameters = {
      "account_reference": this.state.customer.accountReference
    }
    const config = {
      headers: { Authorization: "Bearer " + this.context.mpptoken}
    };
    axios.post(this.mppBaseUrl + `/mpp/account/details`,
      bodyParameters,
      config
    ).then(res => {
      this.setState({mppData: res.data})
      console.log(this.state)
    })
    .catch(() => { } );
  }

  updateRenew = (f) => {
    let updateData = this.state.mppData;
    updateData.subscriptions[f].autorenew = !updateData.subscriptions[f].autorenew;
    const bodyParameters = {
      "account_reference": this.state.customer.accountReference,
      "subscription_reference": updateData.subscriptions[f].subscriptionreference,
      "renew": updateData.subscriptions[f].autorenew
    }
    const config = {
      headers: { Authorization: "Bearer " + this.context.mpptoken}
    };
    axios.post(this.mppBaseUrl + `/mpp/account/autorenew`,
      bodyParameters,
      config
      )
      .then(res => {

      })
      .catch(() => { } );
    this.setState({
      mppData: updateData
    });
    console.log(this.state.mppData.subscriptions[f]);
  }


  render() {
    return (
      <div>
        <Header></Header>
        <Container className="customerLookup">
          <CreateAccountModal
            show={this.state.modalShow}
            customer={this.state.customer}
            submit={(form) => this.createNewAccount(form)}
          />
          <h1>Customer Lookup</h1>
          <Row>
            <Col><label htmlFor="emailAddress">Email: </label> <input type="text" name="emailAddress" onChange={this.stateChange} /></Col>
            <Col><label htmlFor="accountId">Account Id: </label> <input type="text" name="accountId" onChange={this.stateChange} /></Col>
          </Row>
            {this.state.error && this.state.customer == null &&
            <div>
              <p>{this.state.error}</p>
              <Button variant="primary" onClick={() => this.setModalShow(true)}>
                Create New Account
              </Button>
            </div>
            }
            {this.state.customer && <Card>
              <Card.Body>
                <h2 className="text-center mb-4">Customer Information</h2>
                <Row>
                  <Col><strong>Name: </strong>{this.state.customer.name}</Col>
                  <Col><strong>Email: </strong>{this.state.customer.email}</Col>
                </Row>
                <Row>
                  <Col><strong>Phone Number: </strong>{this.state.phoneNumber}</Col>
                  <Col><strong>Mobile Number: </strong>{this.state.customer.mobileNumber}</Col>
                </Row>
                <Row>
                  <Col><strong>Date of Birth: </strong>{this.state.customer.dateOfBirth}</Col>
                  <Col><strong>Gender: </strong>{this.state.customer.gender}</Col>
                </Row>
                <Row>
                  <Col><strong>Account Status: </strong>{this.state.customer.status}</Col>
                  <Col><strong>Account Owner: </strong>{this.state.customer.owner}</Col>
                </Row>
                {this.state.customer.owner === "Salesforce" && <Row>
                  <Button className="btn btn-secondary w-100 mt-3" onClick={this.createFromSalesforce}>
                    Create MPP User From Salesforce
                  </Button>
                </Row>}
                {this.state.customer.owner && this.state.customer.owner === "MPP" && <Row>
                  <Col xs={3}>
                  <Button className="btn btn-primary w-100 mt-3" onClick={this.mppManage}>
                    Manage MPP Account
                  </Button>
                  </Col>
                  {!this.state.customer.disableCheckout && <Col xs={3}>
                  <Button className="btn btn-secondary w-100 mt-3" data-email={this.state.customer.email} onClick={this.checkoutStart}>
                    Virtual Checkout
                  </Button>
                  </Col>}
                  <Col xs={3}>
                   <a href={this.eSuiteBaseUrl + "/CustomerAccount/AccountDetails?accountId=" + this.state.customer.accountId} target="_blank" rel="noreferrer" className="btn btn-primary w-100 mt-3">
                    Refund in eSuite
                  </a>
                  </Col>
                  </Row>}
                  {this.state.customer.owner && this.state.customer.owner === "BOTH" && <Row>
                  <Col xs={3}>
                  <Button className="btn btn-primary w-100 mt-3" onClick={this.mppManage}>
                    Manage MPP Account
                  </Button>
                  </Col>
                  <Col xs={3}>
                   <a href={this.eSuiteBaseUrl + "/CustomerAccount/AccountDetails?accountId=" + this.state.customer.accountId} target="_blank" rel="noreferrer" className="btn btn-primary w-100 mt-3">
                    Refund in eSuite
                  </a>
                  </Col>
                  <Col xs={3}>
                      <Button className="btn btn-primary w-100 mt-3" data-email={this.state.customer.email} onClick={this.showPrint}>
                        View Print Subscription
                      </Button>
                  </Col>
                  <Col xs={3}>
                  <Button className="btn btn-secondary w-100 mt-3" data-email={this.state.customer.email} onClick={this.checkoutStart}>
                    Virtual Checkout
                  </Button>
                  </Col>
                  </Row>}
                  {this.state.customer.owner && this.state.customer.owner === "CDS" && <Row>
                    <Col>
                      <Button className="btn btn-primary w-100 mt-3" data-email={this.state.customer.email} onClick={this.showPrint}>
                        View Print Subscription
                      </Button>
                    </Col>
                    <Col>
                      <Button className="btn btn-secondary w-100 mt-3" data-id={this.state.customer.id} onClick={this.createFromCDS}>
                        Convert To MPP
                      </Button>
                    </Col>
                  </Row>}
                
              </Card.Body>
            </Card>}
        </Container>
        {this.state.checkout && <Container className="checkoutcontainer">
        <Card>
              <Card.Header>Checkout</Card.Header>
              {this.state.checkoutStep === 0 && <Card.Body>
                <h2 className="mb-4">Validate Goodsam Membership</h2>
                <GoodsamValidate
                skip = { this.skipGoodsamValidation }
                validate = { this.validateGoodsam }
                ></GoodsamValidate>
              </Card.Body>}
              {this.state.checkoutStep === 1 && <Card.Body>
                <Row>
                  <Col>
                    <h2 className="mb-4">Select Plan {this.state.goodsam === true && <span> - Goodsam Valid</span>}</h2>
                  </Col>
                  <Col>
                    {this.state.goodsam === false && <Button variant="secondary" onClick={ () => this.setState({checkoutStep: 0})}>Back to Goodsam Validate</Button>}
                  </Col>
                </Row>
                <PlanSelect
                accountReference = { this.state.customer.accountReference }
                goodsam = {this.state.goodsam }
                upgrade = {this.state.upgrade }
                auth = {this.state.customer.sessionToken}
                handleSubmit={ (product, contract, cartReference) => this.SelectPlan(product, contract, cartReference) }
                ></PlanSelect>
              </Card.Body>}
              {this.state.checkoutStep === 2 && <Card.Body>
                <h2 className="mb-4">Add Address</h2>
                <AddAddress
                customer = {this.state.customer}
                buttonText = "Continue to Payment"
                handleSubmit={ (addressReference, deliveryReference,address) => this.AddAddress(addressReference, deliveryReference,address) }
                ></AddAddress>
              </Card.Body>}
              {this.state.checkoutStep === 3 && <Card.Body>
                <h2 className="mb-4">Add Card</h2>
                <AddCard
                accountReference = { this.state.customer.accountReference }
                auth = {this.state.customer.sessionToken}
                buttonText = "Submit Order"
                handleSubmit={ () => this.AddCard() }
                ></AddCard>
              </Card.Body>}
              {this.state.checkoutStep === 4 && <Card.Body>
                <h2 className="mb-4">Confirm Order</h2>
                <ConfirmCheckout
                checkoutData = { this.state.checkoutData }
                customer = { this.state.customer }
                goodsam = { this.state.goodsam }
                upgrade = {this.state.upgrade }
                handleSubmit= { (status) => this.CompleteCheckout(status) }
                ></ConfirmCheckout>
              </Card.Body>}
              {this.state.checkoutStep === 5 && <Card.Body>
                <h2 className="mb-4">Checkout Complete</h2>
                <CheckoutComplete
                finalResponse = { this.state.cartPurchaseResponse }></CheckoutComplete>
              </Card.Body>}
            </Card>
        </Container>}
        {this.state.showprint && <Container className="printcontainer">
          <Card>
              <Card.Header>CDS Print Subscription Information</Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                  <h3>Account Information</h3>
                  <p>Account Number: { this.state.printData.accountNumber }<br/>
                  Starting Issue: { this.state.printData.startIssueDescription }<br/>
                  Last Issue Received: { this.state.printData.lastIssueDescription }<br/>
                  Expiration Issue: { this.state.printData.expireIssueDescription }<br/>
                  Subscription Status: { this.state.printData.serviceStatusDescription }
                  </p>
                  </Col>
                  <Col>
                  <h3>Shipping Address Information</h3>
                  <p>{ this.state.printData.address1 } { this.state.printData.address2 }<br/>
                  { this.state.printData.city }, {this.state.printData.state} {this.state.printData.zipCode}<br/>
                  { this.state.printData.country }
                  </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
        </Container>}
        {this.state.mppData && this.state.mppManage === null && <Container className="mppmanagecontainer">
        <Card>
              <Card.Header>Manage MPP Account</Card.Header>
              <Card.Body>
                <Row>
                  {this.state.mppData.card && <Col>
                  <h3>Current Card</h3>
                  <p>{this.state.mppData.card.cardType} ending in {this.state.mppData.card.lastfour}<br/>Expires: {this.state.mppData.card.expires}</p>
                  <Button className="btn btn-primary w-100 mt-3" onClick={() => this.setState({mppManage: "Card"})}>
                    Update Card
                  </Button>
                  </Col>}
                  {this.state.mppData.address && <Col>
                  <h3>Current Shipping Address</h3>
                  <p>{ this.state.mppData.address.street } { this.state.mppData.address.houseNumber }<br/>
                  { this.state.mppData.address.townCity }, {this.state.mppData.address.state} {this.state.mppData.address.postCode}<br/>
                  { this.state.mppData.address.country }</p>
                  <Button className="btn btn-primary w-100 mt-3" onClick={() => this.setState({mppManage: "Shipping"})}>
                    Update Shipping
                  </Button>
                  </Col>}
                </Row>
                {this.state.mppData.subscriptions && <Row>
                  <Col xs={12}><h3>Subscriptions</h3></Col>
                  {this.state.mppData.subscriptions.map((value,index) =>
                  {
                    return <Col>
                    <p>{value.length} to {value.items}<br/>Auto Renew: {value.autorenew ? 'Active' : 'Inactive'}<br/> Expires {value.expires}</p>
                    <Button className="btn btn-primary w-100 mt-3" onClick={ (f) => this.updateRenew(index) }>
                    {value.autorenew ? 'Cancel Auto Renew' : 'Enable Auto Renew'}
                    </Button>
                    </Col>
                  })}
                </Row>}
              </Card.Body>
            </Card>
        </Container>}
        {this.state.mppManage && <Container className="mppmanagecontainer">
        <Card>
              <Card.Header>Update {this.state.mppManage}</Card.Header>
              <Card.Body>
                <Row>
                  {this.state.mppManage === "Shipping" && <Col>
                  <AddAddress
                  customer = {this.state.customer}
                  buttonText = "Update Shipping"
                  handleSubmit={ (addressReference, deliveryReference,address) => this.AddAddress(addressReference, deliveryReference,address) }
                  ></AddAddress>
                  </Col>}
                  {this.state.mppManage === "Card" && <Col>
                  <AddCard
                    accountReference = { this.state.customer.accountReference }
                    auth = {this.state.customer.sessionToken}
                    buttonText = "Update Card"
                    handleSubmit={ () => this.AddCard() }
                    ></AddCard>
                  </Col>}
                </Row>
              </Card.Body>
            </Card>
        </Container>}
      </div>
    )
  }
}
