import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import axios from 'axios';
import Dashboard from "./Dashboard"
import { ApiContext } from '../contexts/apicontext'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.baseUrl = process.env.REACT_APP_BASE_URL;
    this.apiUsername = process.env.REACT_APP_API_USERNAME;
    this.apiPassword = process.env.REACT_APP_API_PASSWORD;
    this.mppBaseUrl = process.env.REACT_APP_MPP_BASE_URL;
    this.mppApiUsername = process.env.REACT_APP_MPP_API_USERNAME;
    this.mppApiPassword = process.env.REACT_APP_MPP_API_PASSWORD;
    this.state = {token : ""}
  }

  componentDidMount() {
    this.updateToken()
    this.updateMPPToken()
  }

  updateToken = () => {
      const bodyParameters = {
        "username": this.apiUsername,
        "password": this.apiPassword
    };
    axios.post(this.baseUrl + `/api/authenticate`,
        bodyParameters
    )
    .then(res => {
      this.setState({token: res.data.token})
    })
    .catch((error) => { console.log(error) } );
  }

  updateMPPToken = () => {
    const bodyParameters = {
      "username": this.mppApiUsername,
      "password": this.mppApiPassword
  };
  axios.post(this.mppBaseUrl + `/api/authenticate`,
      bodyParameters
  )
  .then(res => {
    console.log(res);
    this.setState({mpptoken: res.data.token})
  })
  .catch((error) => { console.log(error) } );
}
  
  render() {
    return (
      <ApiContext.Provider value={{token: this.state.token, mpptoken: this.state.mpptoken, updateToken: this.updateToken, mppUpdateToken: this.updateMPPToken}}>
        <div>
          <Router>
              <Switch>
                <Route exact path="/" component={Dashboard} />
              </Switch>
          </Router>
        </div>
      </ApiContext.Provider>
    )
  }
}

export default App
