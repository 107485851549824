import React, { Component } from "react"
import Select from 'react-select'
import axios from 'axios';
import { Container, Row, Col, Button} from "react-bootstrap"
import { ApiContext } from '../../contexts/apicontext';

export default class PlanSelect extends Component {
    constructor(props) {
        super(props);
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.mppBaseUrl = process.env.REACT_APP_MPP_BASE_URL;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.states = [
                {value: 'AL', label: 'Alabama'},
                {value: 'AK', label: 'Alaska'},
                {value: 'AZ', label: 'Arizona'},
                {value: 'AR', label: 'Arkansas'},
                {value: 'CA', label: 'California'},
                {value: 'CO', label: 'Colorado'},
                {value: 'CT', label: 'Connecticut'},
                {value: 'DE', label: 'Delaware'},
                {value: 'DC', label: 'District of Columbia'},
                {value: 'FL', label: 'Florida'},
                {value: 'GA', label: 'Georgia'},
                {value: 'HI', label: 'Hawaii'},
                {value: 'ID', label: 'Idaho'},
                {value: 'IL', label: 'Illinois'},
                {value: 'IN', label: 'Indiana'},
                {value: 'IA', label: 'Iowa'},
                {value: 'KS', label: 'Kansas'},
                {value: 'KY', label: 'Kentucky'},
                {value: 'LA', label: 'Louisiana'},
                {value: 'ME', label: 'Maine'},
                {value: 'MD', label: 'Maryland'},
                {value: 'MA', label: 'Massachusetts'},
                {value: 'MI', label: 'Michigan'},
                {value: 'MN', label: 'Minnesota'},
                {value: 'MS', label: 'Mississippi'},
                {value: 'MO', label: 'Missouri'},
                {value: 'MT', label: 'Montana'},
                {value: 'NE', label: 'Nebraska'},
                {value: 'NV', label: 'Nevada'},
                {value: 'NH', label: 'New Hampshire'},
                {value: 'NJ', label: 'New Jersey'},
                {value: 'NM', label: 'New Mexico'},
                {value: 'NY', label: 'New York'},
                {value: 'NC', label: 'North Carolina'},
                {value: 'ND', label: 'North Dakota'},
                {value: 'OH', label: 'Ohio'},
                {value: 'OK', label: 'Oklahoma'},
                {value: 'OR', label: 'Oregon'},
                {value: 'PA', label: 'Pennsylvania'},
                {value: 'RI', label: 'Rhode Island'},
                {value: 'SC', label: 'South Carolina'},
                {value: 'SD', label: 'South Dakota'},
                {value: 'TN', label: 'Tennessee'},
                {value: 'TX', label: 'Texas'},
                {value: 'UT', label: 'Utah'},
                {value: 'VT', label: 'Vermont'},
                {value: 'VA', label: 'Virginia'},
                {value: 'WA', label: 'Washington'},
                {value: 'WV', label: 'West Virginia'},
                {value: 'WI', label: 'Wisconsin'},
                {value: 'WY', label: 'Wyoming'},
        ]

        this.countries = [
          {value:"United States", label: "United States"},
          {value:"Canada", label: "Canada"}
        ]

        this.addressline1 = React.createRef();
        this.addressline2 = React.createRef();
        this.city = React.createRef();
        this.addressState = React.createRef();
        this.zipcode = React.createRef();
        this.country = React.createRef();
    }

    static contextType = ApiContext

    handleSubmit() {
        console.log("Next Step!");
        const street = this.addressline1.current.value;
        const flatNumber = this.addressline2.current.value;
        const city = this.city.current.value;
        const addressState = this.addressState.current.state.value.value;
        const country = this.country.current.state.value.value;
        const zipcode = this.zipcode.current.value;
        const firstName = this.props.customer.firstName
        const lastName = this.props.customer.lastName
        const accountReference = this.props.customer.accountReference
        const bodyParameters = {
          "firstName":firstName,
          "lastName":lastName,
          "street":street,
          "street2":flatNumber,
          "city":city,
          "state":addressState,
          "country":country,
          "zip":zipcode,
          "account_reference":accountReference
        };
        const config = {
          headers: { Authorization: "Bearer " + this.context.mpptoken}
        };
        axios.post(this.mppBaseUrl + `/mpp/account/address`,
          bodyParameters,
          config
        )
        .then(res => {
            const response = res.data;
            if(response.status === "success") {
              this.props.handleSubmit(response.addressReference, response.deliveryReference, response.address);
            }
        })
        .catch((error) => { console.log(error) } );
    }

    
    config = {
        headers: { Authorization: "Bearer " + process.env.REACT_APP_MIDDLEWARE_AUTH }
    };

    state = {
      customerAddress: null,
      addressIdentifier: null,
      street:"",
      flatNumber:"",
      city:"",
      addressState:"",
      zipcode:"",
      country:""
    }

  componentDidMount() {
    const bodyParameters = {
        "account_reference": this.props.customer.accountReference
    };
    const config = {
      headers: { Authorization: "Bearer " + this.context.mpptoken}
    };
    axios.post(this.mppBaseUrl + `/mpp/address/default`,
      bodyParameters,
      config
    )
    .then(res => {
      const response = res.data;
      if(response.status === "success") {
        console.log(response.addressReference);
        this.setState({
          street:response.addressReference.street,
          flatNumber:response.addressReference.houseNumber,
          city:response.addressReference.townCity,
          addressState:response.addressReference.state,
          zipcode:response.addressReference.postCode,
          country:response.addressReference.country
        });
      }
  }).catch((error) => { console.log(error) } );
  }



  render() {
    return (
    <Container>
        <Row>
            <Col><h3>Shipping Info</h3></Col>
        </Row>
        <Row>
          <Col><label htmlFor="addressline1">Address Line 1: </label> <input type="text" ref={this.addressline1} name="addressline1" value={this.street} /></Col>
          <Col><label htmlFor="addressline2">Address Line 2: </label> <input type="text" ref={this.addressline2} name="addressline2" /></Col>
        </Row>
        <Row>
          <Col><label htmlFor="city">City: </label> <input type="text" ref={this.city} name="city"/></Col>
          <Col><label htmlFor="zipcode">Zip Code: </label> <input type="text" ref={this.zipcode} name="zipcode" /></Col>
        </Row>
        <Row>
          <Col><label htmlFor="addressState">State: </label><Select options={this.states} ref={this.addressState}></Select></Col>
          <Col><label htmlFor="country">Country: </label><Select options={this.countries} ref={this.country}></Select></Col>
        </Row>
        <Button className="btn btn-secondary w-100 mt-3" onClick={this.handleSubmit}>
                    {this.props.buttonText}
        </Button>
    </Container>
    );
  }

}