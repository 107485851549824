import React, { Component } from "react"

export default class CheckoutComplete extends Component {

  render() {
    return (
      <div><p>Order Confirmation Number: {this.props.finalResponse.orderReference} </p>
      <p>Amount Charged: {this.props.finalResponse.amountCharged}</p></div>
    );
  }

}