import React, { Component } from "react"

export default class AddCard extends Component {
  constructor(props) {
    super(props);
    this.msgHandler = this.msgHandler.bind(this);
    this.send = this.send.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.eSuiteBaseUrl = process.env.REACT_APP_MPP_IFRAME_URL
  }

  
  send() {
    if (this.container && this.container.contentWindow) {
      this.container.contentWindow.postMessage(this.data, '*');
      this.container.scrollTop = 20;
    }

    if (!this.received) {
      setTimeout(this.send, 200);
    }
  }

  componentDidMount() {
    this.previous = false;
    this.next = false;
    this.data = {
        call:'sendData',
        buttontext:this.props.buttonText, 
        account:this.props.accountReference,
        auth:this.props.auth};
    this.send();
    this.reveived = false;
    window.addEventListener('message', this.msgHandler);
  }

  msgHandler(e) {
    if (e.data.url !== this.props.content) {
      // console.info(e.data.url);
      return;
    }

    console.log(e.data);
    if(e.data.call === "sendData") {
        this.received = true;
        this.props.handleSubmit()
    }

  }

  render() {
    return (
      <div>
        <iframe
          ref = {e => {
            this.container = e;
          }}
          width = '100%'
          height = '500px'
          title = "Add Credit Card"
          src = { this.eSuiteBaseUrl }
        />
      </div>
    )
  }
}
